<template>
  <div class="myPost">
    <Nav></Nav>
    <div class="myPost-con minheight">
      <div class="myPost-title">
        <span> <a href="/index.html" target="_blank">首页</a></span
        >>我的稿件
      </div>
      <div class="myPost-detail">
        <div class="detail-tip">
          <div>在“我的图库”里，您将可以看到您上传的全部照片，</div>
          <div>其中全部通过用“ 全部上网 ”表示， 未获通过用“ 全部下网 ”表示，部分通过用“ 部分上网 ”表示，</div>
          <div>正在编辑用“ 正在编辑 ”表示， 尚未编辑用“ 尚未编辑 ”表示。</div>
        </div>
        <div class="detail-page">
          <div class="page-container">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pager.currentPage"
              :page-size="pager.pageSize"
              :hide-on-single-page="true"
              layout="prev, pager, next, jumper"
              :total="pager.total"
            ></el-pagination>
          </div>
        </div>
        <div class="detail-con">
          <el-table :data="myPostList" stripe style="width: 100%" @row-dblclick="onGoPost">
            <el-table-column label="图片标题" width="180">
              <template slot-scope="scope">
                <span class="title">
                  <a target="_blank" :href="'post.html?id=' + scope.row.id">{{ scope.row.title }}</a></span
                >
                <span v-if="scope.row.canEdit" class="edit" @click.stop="onGoEdit(scope.row.id)">(编辑稿件)</span>
              </template>
            </el-table-column>
            <el-table-column prop="postTypeName" label="类型"> </el-table-column>
            <el-table-column prop="firstEditorName" label="初审"> </el-table-column>
            <el-table-column prop="lastEditorName" label="再审"> </el-table-column>
            <el-table-column prop="lastSignerName" label="签发" v-if="type == 'PIC'"> </el-table-column>
            <el-table-column prop="eventAddrPath" label="发生地点" width="150"> </el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope"> {{ scope.row.countPicOnnet }}/{{ scope.row.countPic }} </template>
            </el-table-column>
            <el-table-column prop="eventTime" label="发生时间" width="150"> </el-table-column>
            <el-table-column prop="lastSignerName" label="状态" v-if="type == 'PIC'"></el-table-column>
            <el-table-column label="签发数" v-if="type == 'PIC'">
              <template slot-scope="scope"> {{ scope.row.signed }}张 </template>
            </el-table-column>
            <el-table-column label="见报数" v-if="type == 'PIC'">
              <template slot-scope="scope"> {{ scope.row.published }}张 </template>
            </el-table-column>
            <!-- <el-table-column label="下载数">
              <template slot-scope="scope"> {{ scope.row.downloadCount }}张 </template>
            </el-table-column> -->
          </el-table>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      myPostList: [], // 我的稿件列表
      pager: {
        currentPage: 1,
        total: 1,
        pageSize: 20,
      }, // 分页条件
      type: '',
    }
  },
  created() {
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        console.log('mypost', data)
      })
      .catch(() => {
        console.log('catch')
        this.$alert('您当前处于未登录状态, 请先登录?', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
        })
          .then(() => {
            this.$router.push('/index.html')
          })
          .catch(() => {})
      })
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      RD.myPost()
        .listPost(this.pager.currentPage)
        .then((data) => {
          data.list.forEach((i) => {
            i.postTypeName = i.postType == 'PIC' ? '图片稿' : i.postType == 'VIDEO' ? '视频稿' : ''
            if (i.postType == 'PIC') {
              thiz.type = 'pic'
            } else {
              thiz.type = 'video'
            }
            if (i.postType == 2) {
              i.ImgURL = RD.pic().id(i.frontPicId).sImage_URL()
            } else if (i.postType == 3) {
              i.ImgURL = RD.video().id(i.id).poster_URL()
            }
          })
          thiz.myPostList = data.list
          thiz.pager = data.pager
        })
    },
    // 分页
    handleCurrentChange(val) {
      var thiz = this
      this.pager.currentPage = val
      thiz.reload()
    },
    // 编辑稿件
    onGoEdit(postId) {
      let routerUrl = this.$router.resolve({
        path: '/postEdit.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
    // 浏览稿件
    onGoPost(row) {
      var postId = row.id
      let routerUrl = this.$router.resolve({
        path: '/post.html',
        query: { id: postId },
      })
      window.open(routerUrl.href, '_blank')
    },
  },
}
</script>

<style scoped lang="less">
.myPost {
  background-color: #f1f1f1;
}
@media all and (max-width: 800px) {
  .myPost-con {
    width: 750px;
    margin: 0 auto;
    min-height: 585px;
    .myPost-title {
      height: 56px;
      background-color: #dfdfdf;
      font-size: 1.5rem;
      line-height: 45px;
      text-align: left;
      padding-left: 2px;
      span {
        margin-right: 5px;
      }
    }
    .myPost-detail {
      height: auto;
      background-color: #dfdfdf;
      width: 100%;
      .detail-tip {
        height: 98px;
        div {
          height: 30px;
          font-size: 1.3rem;
          line-height: 30px;
        }
      }
      .detail-page {
        height: 56px;
      }
      .detail-con {
        .detail-item {
          width: 750px;
          height: 200px;
          display: flex;
          margin-bottom: 20px;
          .post-img {
            width: 400px;
            height: 100%;
            background-size: cover;
            margin-left: 10px;
            a {
              display: inline-block;
              width: 100%;
              height: 100%;
            }
          }
          .post-content {
            margin-left: 10px;
            .item {
              font-size: 16px;
              text-align: left;
              height: 25px;
              line-height: 25px;
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .myPost-con {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 25.9rem);
    .myPost-title {
      height: 4.5rem;
      background-color: #dfdfdf;
      font-size: 1.5rem;
      line-height: 4.5rem;
      text-align: left;
      padding-left: 2px;
      span {
        margin-right: 5px;
      }
    }
    .myPost-detail {
      height: auto;
      background-color: #dfdfdf;
      width: 100%;
      .detail-tip {
        height: 98px;
        div {
          height: 30px;
          font-size: 1.3rem;
          line-height: 30px;
        }
      }
      .detail-page {
        height: 5.6rem;
      }
    }
  }
}
.title {
  cursor: pointer;
  a {
    text-decoration: none;
    color: #606266;
  }
  a:visited {
    color: #606266;
  }
}
.edit {
  color: red;
  cursor: pointer;
}
</style>
